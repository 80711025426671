import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

//matrial
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';

import { FormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipe/safe-html.pipe';

import { AppRoutingModule } from './app-routing.module';

//componentes
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavBarFeComponent } from './component/estruct/nav-bar-fe/nav-bar-fe.component';
import { HeaderFeComponent } from './component/estruct/header-fe/header-fe.component';
import { ArticleFeComponent } from './component/estruct/article-fe/article-fe.component';
import { FooterFeComponent } from './component/estruct/footer-fe/footer-fe.component';
import { ForoComponent } from './component/foro/foro.component';
import { ForoContainerComponent } from './component/foro-container/foro-container.component';
import { ErrorComponent } from './component/error/error.component';



@NgModule({
  declarations: [
    AppComponent,
    NavBarFeComponent,
    HeaderFeComponent,
    ArticleFeComponent,
    FooterFeComponent,
    ForoComponent,
    ForoContainerComponent,
    ErrorComponent,
    SafeHtmlPipe

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

//materail
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatMenuModule,

    FormsModule
  ],
  providers: [
    
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

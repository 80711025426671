import { Component } from '@angular/core';

@Component({
  selector: 'header-fe',
  templateUrl: './header-fe.component.html',
  styleUrls: ['./header-fe.component.scss']
})
export class HeaderFeComponent {
  constructor() { }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'footer-fe',
  templateUrl: './footer-fe.component.html',
  styleUrls: ['./footer-fe.component.scss']
})
export class FooterFeComponent  {

  constructor() { }

 
}

import { Component, OnInit } from '@angular/core';
import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { ActivatedRoute, Params } from '@angular/router';
import { ConatinerForoAutorI } from 'src/app/model/container-foro-autor-interface';
@Component({
  selector: 'app-foro-container',
  templateUrl: './foro-container.component.html',
  styleUrls: ['./foro-container.component.scss']
})
export class ForoContainerComponent implements OnInit {
  public editorData = '';
  urltitle: string;
  post: string;
  title: string;
  forum: ConatinerForoAutorI;
  existForun: boolean;
  constructor(
    private rutaActiva: ActivatedRoute
  ) {
    this.editorData = '';
   }
 
  ngOnInit() {
 
    this.forumss();
  }
  forumss() {
    if(this.rutaActiva.snapshot.params.blog == "informacion"){
      this.post = "Infomracion"
 
    
    this.urltitle = "/forums/"+this.rutaActiva.snapshot.params.blog ;
    if (this.rutaActiva.snapshot.params.container == "reglamento-general") {
      this.title = "reglamento-general";
      this.forum = null;
      this.editorData = null;
      
      this.existForun = true;
      this.forum = {
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/466723213724876801/a_073d4d7e0b5e57667fe9882cd7c1fd29.gif",
        "datetime": "30.08.2019",
        "rol": "senior",
        "titlePost": "Reglamento General",
        "nickname": "! Lil Peluchin",
        "bolComents": false,
        "temas":2,
        "container": `<p style="text-align:center !important"><span style="font-size:24px"><span style="font-family:Arial,Helvetica,sans-serif"><strong>REGLAS</strong></span></span></p>

        <p style="text-align:justify"><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>REGLAS B&Aacute;SICAS EN ROL&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">1. Est&aacute; totalmente prohibido salirse de rol sin presencia de un staff con su respectivo TAG, &eacute;ste a su vez decidir&aacute; si el rol debe continuar o no</span><span style="color:#000000">.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2. Siempre debes valorar la vida.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. Queda prohibido hacer Power Gaming. (Acciones que ser&iacute;an imposibles de realizar en la vida real)&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">4. Queda prohibido hacer Meta Gaming.(Usar informaci&oacute;n adquirida fuera del rol, para beneficio dentro de rol).&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">5. Est&aacute; prohibido matar sin rol previo.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6. Est&aacute; prohibido forzar un rol, sin excepciones.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">7. No se puede tener secuestrado a un usuario por m&aacute;s de 20 min con excepciones de ser reh&eacute;n para un robo organizado.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">8. Est&aacute; prohibido iniciar rol agresivo en zonas seguras(Insultar, Acosar, Ofender etc.). 9. Fuera de rol siempre deben tratarse con respeto por ende queda totalmente prohibido cualquier tipo de agresi&oacute;n verbal(origen, raza, sexo, idioma, religi&oacute;n, opini&oacute;n, condici&oacute;n econ&oacute;mica o de cualquier otra &iacute;ndole).&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">10. Est&aacute; prohibido realizar rol agresivo (robar, matar, insultar o secuestrar) a las facciones legales en servicio, a excepci&oacute;n de la Polic&iacute;a.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">11. Est&aacute; prohibido usar cascos como m&aacute;scara, chalecos antibalas como torso y ponerse paraca&iacute;das al registrar su personaje o tambi&eacute;n luego de registrar su PJ.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">12. Est&aacute; prohibido usar m&aacute;scaras(Incluye todas las que puedas encontrar en tienda de m&aacute;scaras) y portar armas visibles en zonas seguras.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">13. Est&aacute; prohibido disparar directamente al individuo dentro del veh&iacute;culo, solo se podr&aacute;n pinchar las ruedas, o bien al chasis del mismo, o viceversa no puedes disparar a nadie a matar estando en un veh&iacute;culo.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">14. Est&aacute; prohibido ponerse nombres troll, personas famosas y/o conocidas. Caso contrario ser&aacute; sancionado y obligado a registrar nuevamente su PJ.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">15. Est&aacute; prohibido robar sin previo rol.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">16. No est&aacute; permitido hacer BunnyJump con el fin esquivar balas o tener alguna otra ventaja. 17. In Character (IC) es todo aquello que le sucede a nuestro personaje, nuestro progreso. 18. Est&aacute; prohibido cualquier tipo de rol agresivo sin EMS, m&iacute;nimo se necesita uno(1) en servicio.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">19. No est&aacute; permitido robar a civiles sin la opci&oacute;n de la &quot;G&quot;.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">20. Est&aacute; prohibido realizar cualquier acto ilegal(Venta de armas, drogas y/o estafas) dentro de las zonas seguras.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">21. Prohibido robar/estafar sin polic&iacute;as en servicio.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">22. M&iacute;nimo se necesitan 3 polic&iacute;as en servicio y un(1) same en caso de robo a mano armada a civiles.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">23. El m&aacute;ximo de participantes en un robo particular es de 4.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">24. No est&aacute; permitido cualquier tipo de acto de car&aacute;cter sexual, a menos que haya consentimiento por ambas partes.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">25. Debes tener micr&oacute;fono para poder rolear, de lo contrario ser&aacute;s sancionado de manera permanente con opci&oacute;n a apelar.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">26. No est&aacute; permitido el uso de la ropa del trabajo para cosas externas a ello. 27. No est&aacute; permitido trabajar sin la ropa de la empresa.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">28. Est&aacute; prohibido usar autos de Gama Premium para robos particulares.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">29. Est&aacute; totalmente prohibido matar a cualquiera que valore su vida.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">30. Toda zona segura queda anulada si hubo un rol previo de persecuci&oacute;n, evita usar las zonas seguras de no ser necesarias.</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>REGLAS DE VIDA</strong></span><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Al morir o ser reanimado tu personaje no puede recordar el rol que lo llev&oacute; a su muerte y todo lo anterior a 20 min.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2. No est&aacute; permitido guardar tu veh&iacute;culo en tus garajes en rol de persecuci&oacute;n y/o orden de captura.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. Al perder toda la barra de la vida se considera estado de inconsciente, de no ser reanimado se considera muerte.(PK)&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">4. No puedes regresar al punto donde has muerto/reanimado para continuar el mismo rol antes de los 20 min.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>REGLAS VEHICULARES&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Est&aacute; totalmente prohibido dejar por cualquier lado los veh&iacute;culos de trabajo y/o personales. 2. Solo los acompa&ntilde;antes del conductor pueden disparar a las llantas o chasis del veh&iacute;culo para buscar inhabilitar, recuerden jam&aacute;s disparar al cuerpo a los tripulantes. </span></span><span style="font-size:8.001712799072266pt"><span style="font-family:Arial">(Ver 13.) </span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. Est&aacute; prohibido el uso de tu veh&iacute;culo como arma para inhabilitar a otro jugador (VDM). 4. Los veh&iacute;culos de trabajo son de uso exclusivo para trabajar, </span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><s>no puedes usar un auto</s></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd"><s>particular para trabajar.</s></span><span style="color:#000000"><s> </s></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ffffff"><span style="background-color:#cc0000">Queda deshabilitado temporalmente el no poder usar un auto</span></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ffffff"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ffffff"><span style="background-color:#cc0000">particular para trabajar. (No aplica para Electricista ni Distribuidor)</span></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para realizar un secuestro a un polic&iacute;a se necesita un m&aacute;ximo de 4 participantes, en caso de formarse un tiroteo pueden intervenir otras personas as&iacute; mismo haciendo un m&aacute;ximo de 8 participantes en el rol.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2. Para secuestrar a un civil, ser&aacute; con un m&aacute;ximo de 8 participantes.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. No est&aacute; permitido robar veh&iacute;culos de trabajo, mucho menos veh&iacute;culos policiales/hospital/mec&aacute;nico.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">4. &Uacute;nicamente podr&aacute;s robarle las pertenencias a un polic&iacute;a si lo tienes secuestrado, en la forma expresamente indicada y permitida.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">5. Robos particulares y cualquier tiroteo ser&aacute;n con armas grado 1.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6. No puedes robarles a personas, ni ba&uacute;les siendo menor a lvl 10.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">7. Prohibido robar agua y comida.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:8.001712799072266pt"><span style="font-family:Arial"><strong>Ref.(21) Para iniciar un robo se necesita 3 polic&iacute;as y 1 same, ambos en servicio.&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:8.001712799072266pt"><span style="font-family:Arial"><strong>Ref.(25) No est&aacute; permitido robar con la ropa de trabajo.&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:8.001712799072266pt"><span style="font-family:Arial"><strong>Ref.(28) Si la v&iacute;ctima valora su vida no puedes matarlo.&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>A. Secuestrar Polic&iacute;as:&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">a. A partir de 8 a m&aacute;s Polic&iacute;as en servicio se puede secuestrar a un solo Polic&iacute;a. b. A partir de 10 a m&aacute;s polic&iacute;as en servicio se puede secuestrar a dos (2) Polic&iacute;as. c. A partir de 12 a m&aacute;s polic&iacute;as en servicio se puede secuestrar a tres (3) Polic&iacute;as. d. A partir de 14 a m&aacute;s polic&iacute;as en servicio se puede secuestrar a cuatro (4) Polic&iacute;as.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>B. Secuestrar Civil:&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">a. A partir de 2 Polic&iacute;as en servicio se puede tomar como reh&eacute;n a un solo civil. b. A partir de 3 Polic&iacute;as en servicio se puede tomar como reh&eacute;n a dos (2) civil. c. A partir de 6 Polic&iacute;as en servicio se puede tomar como reh&eacute;n a cuatro (4) civil.</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:14.006750106811523pt"><span style="font-family:Arial"><strong>ROBOS ORGANIZADOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>REGLAS GENERALES&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para iniciar cualquier atraco ya sea a tienda, joyer&iacute;a, armer&iacute;a, banco chico, banco central o tienda de tecnolog&iacute;a, se deber&aacute; preguntar previamente si hay polic&iacute;as disponibles para atender el atraco. Esto se deber&aacute; realizar mediante un tweet an&oacute;nimo utilizando el comando /anon.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2. Una vez contestado el tweet an&oacute;nimo y se cumplan con los requisitos, se podr&aacute; efectuar el atraco.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. Queda totalmente prohibido el uso de cascos&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">4. En caso de querer negociar por los rehenes. Este rol tendr&aacute; un m&aacute;ximo de 10 minutos, pasado este tiempo se romper&aacute;n las negociaciones.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">5. Al romperse las negociaciones se procede con un conteo de 10 seg. v&iacute;a tweet para iniciar un tiroteo. En caso de que el tirador se encuentre expuesto y realice disparos, se rompe negociaciones y se da un tiempo de 3 seg para posicionarse.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6. Si la banda criminal posee rehenes, estos tendr&aacute;n opci&oacute;n a negociar la libertad de cada uno de ellos. Se podr&aacute; negociar &uacute;nicamente por alg&uacute;n beneficio de huida limpia o Mississippi (segundos) para su huida, tambi&eacute;n se podr&aacute; negociar por ambas cosas, pero queda totalmente prohibida la negociaci&oacute;n por dinero. En caso de no llegar a ning&uacute;n acuerdo, se romper&aacute;n las negociaciones autom&aacute;ticamente.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">7. No se deber&aacute; da&ntilde;ar f&iacute;sicamente a los rehenes, en caso de que estos cooperen. 8. Para cualquier tipo de robo organizado, se deber&aacute; contar con un m&iacute;nimo de 1 EMS en servicio.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">9. En el momento de negociaci&oacute;n, el polic&iacute;a negociador no deber&aacute; ser abatido por ninguna circunstancia.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">10. La huida de los criminales deber&aacute; realizarse &uacute;nicamente por caminos aptos para la circulaci&oacute;n vehicular. En caso de irse por escaleras, monta&ntilde;as u otros, deber&aacute; contarse con las autopartes adecuadas incluidas en el veh&iacute;culo, en caso contrario ser&aacute; considerado Power Gaming.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">11. Para la detenci&oacute;n de la escapatoria de los criminales, est&aacute; permitido que el cuerpo policial pueda abrir fuego contra los o el veh&iacute;culo de los atracadores.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">12. Queda totalmente prohibida la alianza entre rehenes y criminales.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">13. Si la polic&iacute;a no llega al atraco en un plazo de 10 minutos con la previa afirmaci&oacute;n policial, los delincuentes podr&aacute;n huir mandando un anuncio previo mediante un tweet an&oacute;nimo. 14. Si el tirador por parte de los delincuentes es descubierto, este puede abrir fuego. Al suscitarse esto, se rompen negociaciones y se procede a abrir fuego inmediatamente, sin la necesidad de un conteo previo. (3 seg. Para actuar)&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:10.00589370727539pt"><span style="font-family:Arial"><strong>**De no seguir las pautas especificadas anteriormente, se proceder&aacute; a sancionar a los usuarios**&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A TIENDA&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar una tienda, ser&aacute; necesario como m&iacute;nimo 2 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2</span><span style="color:#000000">. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos:</strong></span><span style="color:#000000"><strong> </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2 polic&iacute;as. Uno de estos dos polic&iacute;as, tendr&aacute; la funci&oacute;n de negociador.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3</span><span style="color:#000000">. </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">: 2 atracadores (no est&aacute; permitido tiradores)</span><span style="color:#000000"> </span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">4</span><span style="color:#000000">. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Armas permitidas</strong></span><span style="color:#dddddd"><strong>:</strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">&Uacute;nicamente grado 1&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">5</span><span style="color:#000000">. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1 reh&eacute;n</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A JOYER&Iacute;A&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar una joyer&iacute;a, ser&aacute; necesario como m&iacute;nimo 3 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos durante el robo</strong></span><span style="color:#dddddd"><strong>:</strong></span><span style="color:#000000"><strong> </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3 polic&iacute;as. Las funciones que tendr&aacute;n estos polic&iacute;as ser&aacute;n las siguientes: uno tendr&aacute; la funci&oacute;n de negociador, otro ser&aacute; el encargado de buscar al tirador y el &uacute;ltimo solo tendr&aacute; que esperar las indicaciones del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1">:</span><span style="color:#000000"> </span><span style="color:#dddddd">3 atracadores. Opcionalmente se puede elegir solo a uno de ellos para que cumpla la funci&oacute;n de tirador fuera de la joyer&iacute;a 4. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Armas permitidas</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">&Uacute;nicamente grado 1&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">5.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2 rehenes&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A ARMER&Iacute;A&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar una armer&iacute;a, ser&aacute; necesario como m&iacute;nimo 3 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos durante el robo</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3 polic&iacute;as. Las funciones que tendr&aacute;n estos polic&iacute;as ser&aacute;n las siguientes: uno tendr&aacute; la funci&oacute;n de negociador, y los dos &uacute;ltimos s&oacute;lo tendr&aacute;n que esperar las indicaciones del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3.</span><span style="color:#000000"> </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">: 3 atracadores. No est&aacute; permitido tiradores.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">4.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Armas permitidas</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">&Uacute;nicamente grado 1 y 2.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">5.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2 rehenes.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A BANCO CHICO&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar un banco chico, ser&aacute; necesario como m&iacute;nimo 4 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos durante el robo</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">4 polic&iacute;as. Las funciones que tendr&aacute;n estos polic&iacute;as ser&aacute;n las siguientes: uno tendr&aacute; la funci&oacute;n de negociador, otro ser&aacute; el encargado de buscar al tirador y los dos &uacute;ltimos s&oacute;lo tendr&aacute;n que esperar las indicaciones del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3.</span><span style="color:#000000"> </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">: 3 atracadores. Opcionalmente se puede elegir solo a uno de ellos para que cumpla la funci&oacute;n de tirador fuera del banco chico. 4. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Armas permitidas</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">Est&aacute; permitido el uso de pistolas, escopetas y SMG&rsquo;s. 5. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3 rehenes.</span><span style="color:#000000">&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A BANCO CENTRAL&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar un banco central, ser&aacute; necesario como m&iacute;nimo 6 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos durante el robo</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6 polic&iacute;as. Las funciones que tendr&aacute;n estos polic&iacute;as ser&aacute;n las siguientes: uno tendr&aacute; la funci&oacute;n de negociador, otro ser&aacute; el encargado de buscar al tirador y los cuatro &uacute;ltimos s&oacute;lo tendr&aacute;n que esperar las indicaciones del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3.</span><span style="color:#000000"> </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">: 6 atracadores. Opcionalmente se puede elegir solo a uno de ellos para que cumpla la funci&oacute;n de tirador fuera del banco central 4.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Armas permitidas</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">Est&aacute; permitido el uso de<span style="color:#000000"> </span>pistolas, todo tipo de armas de grado 2 y 3. Pero tan solo con la opci&oacute;n de usar un solo francotirador por cada bando.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">5.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3 rehenes.</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ROBO A TIENDA DE TECNOLOG&Iacute;A&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. Para robar la tienda de tecnolog&iacute;a, ser&aacute; necesario como m&iacute;nimo 5 polic&iacute;as en servicio y que estos est&eacute;n disponibles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">2.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&iacute;nima y m&aacute;xima de polic&iacute;as permitidos durante el robo</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">5 polic&iacute;as. Las funciones que tendr&aacute;n estas ser&aacute;n las siguientes: uno tendr&aacute; la funci&oacute;n de negociador, y el resto solo tendr&aacute;n que esperar las indicaciones del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">3.</span><span style="color:#000000"> </span></span></span><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Cantidad m&aacute;xima de atracadores permitidos</strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">: 5 atracadores. Sin opci&oacute;n de tirador 4. </span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>Armas permitidas: </strong></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">Est&aacute; permitido el uso de pistolas, todo tipo de armas de grado 2 y 3. No est&aacute; permitido el uso de francotiradores.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#dddddd">5.</span><span style="color:#000000"> </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1"><strong>Cantidad m&aacute;xima de rehenes permitidos</strong></span><span style="color:#dddddd"><strong>: </strong></span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3 rehenes.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6. Est&aacute; prohibido disparar de adentro hacia afuera del establecimiento y viceversa. &Uacute;nicamente se podr&aacute; disparar cuando ambos bandos en su totalidad est&aacute;n afuera o dentro del establecimiento.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:12.01007556915283pt"><span style="font-family:Arial"><strong>BASE A ENTORNO&nbsp;</strong></span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">1. No est&aacute; permitido &quot;campear&quot; zonas de trabajo.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">2. No est&aacute; permitido forzar el PK.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">3. El uso del /me, sirve para relatar el pensamiento de un personaje y/o entorno del mismo. 4. El uso del /do, sirve para realizar acciones que no puede hacer el personaje y/o no hay animaci&oacute;n.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">5. En un rol de tiroteo solo pueden participar como m&aacute;ximo 8 jugadores por bando a excepci&oacute;n de la polic&iacute;a.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">6. No est&aacute; permitido meterse a rol ajeno, debes tener un motivo y/o rol previo para acoplarte a un rol sin fastidiar ni interrumpir la experiencia de los otros jugadores.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">7. Si en un tiroteo las balas cesan por 5min, el bando con mayor integrantes vivos que participaron del tiroteo se declaran autom&aacute;ticamente victoriosos y nadie m&aacute;s puede intervenir a excepci&oacute;n de la polic&iacute;a. Cabe recalcar que los 5 min se empiezan desde que un bando pregunta &quot;GG&quot;, en caso el bando contrario no responda en 15 seg. se declara derrota para el bando que no respondi&oacute;.&nbsp;</span></span></span></p>
        
        <p><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">8. En caso de que la polic&iacute;a est&eacute; interviniendo a un miembro tuyo, podr&aacute;n asistir para un rescate en caso de no encontrarse en un rol detenido (poniendo multas), en la forma expresamente indicada y permitida. (No aplica para robos organizados). Para reiniciar el contador de los 5 min. es necesario que los disparos sean directo al cuerpo.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><strong>ZONAS SEGURAS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#ecf0f1">➔ </span><span style="color:#dddddd">Garajes&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Tiendas de ropa/comida/armer&iacute;a/tatuajes&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Farmacias&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Peluquer&iacute;as&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Gasolineras&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔ </span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">Oficina de empleo&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Taller de mec&aacute;nicos&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Comisar&iacute;a&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Hospitales&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Concesionarias&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔ </span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">Zonas de trabajo(vestuarios) y farmeo del mismo.&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Cajeros autom&aacute;ticos&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Gasolineras/Grifos&nbsp;</span></span></span></p>
        
        <p><span style="color:#ecf0f1"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial">➔</span></span></span><span style="color:#dddddd"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"> Plaza Cubos, sus 4 avenidas.</span></span></span></p>
        
        <p>&nbsp;</p>
        
        <p>&nbsp;</p>
        `,

      }
      this.editorData = this.forum.container;
    }
    if (this.rutaActiva.snapshot.params.container == "normas-generales-de-la-policía") {
      this.forum = null;
      this.editorData = null;
      this.existForun = true;
      this.forum = {
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/652196208726638592/07299d18c59eca76344dd970d768bdbd.png",
        "datetime": "30.09.2019",
        "rol": "admin",
        "titlePost": "Normas Generales de la Policía",
        "nickname": "IgnL",
        "bolComents": false,
        "temas":1,
        "container": `<div class="p-1 p-sm-2 p-md-2 p-lg-2" style="background-color:#ffffff">
        <p style="margin-left:17px; text-align:center"><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><img class="d-lg-block d-md-none d-none" src="https://lh6.googleusercontent.com/Ua63Uu_u-A1p8wTK4pj6zKF4uIgETybjU3oKadPc6dL0ZdQwTUqsWklh6X6Sb2vbm7j8sk-AD9AYkyRyx-evtdH7at3JIHu-fDlq9ojpvM9gCbBozcPetvsyGcZjNxIw1q-gSDbX" style="height:88px; width:534px" /></span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><img src="https://lh6.googleusercontent.com/idzUSWHGXhpYg_WQCe0EqdSQ4v_kg0lg8Ii9WD4yhJG5thjiuUPOrQWo4U7xww67KaCM69H2LK96p8bTMgRbE8u_0-3EY7-bDOFy1pYpwbTaTtA996Vqou7gM5tm6IensqecNUqQ" style="height:89px; width:78px" /></span></span></span></p>
        
        <p style="margin-left:35px"><span style="font-size:16.079999923706055pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000"><strong>NORMAS GENERALES DE LA POLICIA &ndash; P.N.P.</strong></span></span></span></span><img src="https://lh4.googleusercontent.com/dELyxOq52qCYjaLp_FyIQ9J8aOO-5pOIyaYfuwvBCbjCaW8BirOyz3Ut6u7jKmFuw_ads4UIWogytr3V-YatJSaXlaTeFlexAyi1ePiJtKzu-_rCFgZKVtLeItp3NGj6CUFQfEJN" style="float:right; height:151px; width:139px" /></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>NORMAS FUNDAMENTALES.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 1.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cada ingresante del cuerpo se har&aacute; cargo del armamento entregado, la p&eacute;rdida del&nbsp; armamento llevar&aacute; una sanci&oacute;n monetaria.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 2.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Respetar siempre el uniforme.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">As&iacute; mismo se proh&iacute;be en su totalidad el uso de m&aacute;scaras, mascarillas, tatuajes faciales, gorras,&nbsp; cadenas, maletas, cabello tintado etc.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 3.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El uso de los veh&iacute;culos otorgados ser&aacute; &uacute;nica y llanamente para uso de patrullaje no para uso&nbsp; personal.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 4.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se proh&iacute;be cualquier acto considerado como corrupci&oacute;n en caso de ser encontrados ser&aacute;n&nbsp; expulsados inmediatamente del cuerpo y se los condenar&aacute; a una cadena perpetua.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 5.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:88px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Todo oficial que se encuentre en servicio deber&aacute; permanecer siempre en la radio policial. </span></span></span></p>
        
        <p style="margin-left:21px; margin-right:88px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 6.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:151px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Valorar su vida, la de sus compa&ntilde;eros y la de los ciudadanos en todo momento. </span></span></span></p>
        
        <p style="margin-left:21px; margin-right:151px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 7.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">La Polic&iacute;a solo har&aacute; uso de su arma de Fuego si se sienten amenazados con cualquier objeto&nbsp; que ponga en peligro su vida o la de su compa&ntilde;ero(s).&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:172px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Teniendo siempre en cuenta el principio de congruencia y proporcionalidad. </span></span></span></p>
        
        <p style="margin-left:21px; margin-right:172px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 8.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Es el deber del oficial leer los derechos a una persona detenida antes de ingresarlo a&nbsp; comisar&iacute;a.&nbsp;</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">1</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh5.googleusercontent.com/KbWUVs4abhz-lm5pVNi65onKNajK0Aw1ulSaIMAhkQxunMnM1rEEYL476OAyNOB-6D9FLQOEbmNwokdQeLtwExEw6CAjFJsikc3W7RqjN0RjV34gT9q8K1seaR5Orw0leH2G315a" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 9.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En una persecuci&oacute;n, los polic&iacute;as tienen permitido obstruir con la patrulla a fin de detener al&nbsp; veh&iacute;culo sospechoso (c&oacute;digo 100). As&iacute; mismo si omiten los 3 avisos enviados o pongan en&nbsp; peligro la vida de los polic&iacute;as o la de los transe&uacute;ntes; es permitido por la polic&iacute;a el uso de la&nbsp; Clave Rober (Disparar a las llantas).&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 10.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:66px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si una patrulla deja de funcionar deber&aacute;n solicitar un mec&aacute;nico para su reparaci&oacute;n. Cuando un oficial anuncie su salida de servicio debe guardar su patrulla.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Las patrullas que hayan sido encontradas abandonadas conllevar&aacute;n una sanci&oacute;n&nbsp; administrativa y monetaria para el propietario de la misma.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 11.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Ser&aacute; responsabilidad de cada oficial el uso correcto de su armamento, as&iacute; como tambi&eacute;n el&nbsp; registrarlo al momento de su asignaci&oacute;n.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 12.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permitir&aacute; que solo los altos rangos de la polic&iacute;a (capit&aacute;n hacia adelante), lleven consigo su&nbsp; pistola de combate cuando se encuentren fuera de servicio.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 13.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Los oficiales tendr&aacute;n permitido el cachear los veh&iacute;culos y propietarios de los mismos siempre&nbsp; y cuando exista una causa probable o sea sospechoso de haber cometido un delito.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 14.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:117px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Un agente estar&aacute; en su completo derecho de pedir a una persona que se identifique, Si el susodicho levanta sospechas el oficial estar&aacute; en su derecho de registrarlo en busca de objetos ilegales o estupefacientes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 15.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:87px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Queda terminantemente prohibido el uso de armas letales de fuego en contra de un civil&nbsp; sospechoso que se encuentre desarmado, solo podr&aacute;n hacer uso de sus armas no letales&nbsp; como el taser o cachiporra.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 16.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:147px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si estas patrullando con un compa&ntilde;ero el encargado de la radio ser&aacute; el copiloto. </span></span></span></p>
        
        <p style="margin-left:21px; margin-right:147px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 17.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En los secuestros no se podr&aacute; disparar a los secuestradores hasta que el reh&eacute;n este fuera de&nbsp; su vista, queda terminantemente prohibido poner en riesgo la vida de los ciudadanos y&nbsp; compa&ntilde;eros del cuerpo.</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">2</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh5.googleusercontent.com/KbWUVs4abhz-lm5pVNi65onKNajK0Aw1ulSaIMAhkQxunMnM1rEEYL476OAyNOB-6D9FLQOEbmNwokdQeLtwExEw6CAjFJsikc3W7RqjN0RjV34gT9q8K1seaR5Orw0leH2G315a" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="text-align:center"><span style="font-size:28.07999992370605pt"><span style="font-family:'Times New Roman'"><span style="color:#000000"><strong>CAPITULO I&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Armamento Reglamentario.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">La P.N.P tendr&aacute; a su disposici&oacute;n varios niveles de armamento para salvaguardar la seguridad&nbsp; de los ciudadanos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 18.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.1 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas reglamentarias nivel 1.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Pistola de combate.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Taser.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cachiporra.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Linterna.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.2 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas reglamentarias nivel 2.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Smg.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Escopeta.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.3 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas reglamentarias nivel 3.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Rifle de Asalto.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Francotirador.&nbsp;</span></span></span></p>
        
        <p style="text-align:center"><span style="font-size:28.07999992370605pt"><span style="font-family:'Times New Roman'"><span style="color:#000000"><strong>CAPITULO II&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Situaciones de robo.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 19.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Con el objetivo de reducir a los sospechosos que priven de la libertad a uno o m&aacute;s ciudadanos&nbsp; o que mediante la intimidaci&oacute;n cometan actos delictivos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Ser&aacute;n reducidos inmediatamente por el accionar policial en base a los siguientes art&iacute;culos&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.1 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Antes de iniciar cualquier robo organizado ya sea locales, tiendas o Bancos, tendr&aacute;n que&nbsp; preguntar a la polic&iacute;a mediante</span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>/anon </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">si el robo en &ldquo;espec&iacute;fico&rdquo; se encuentra disponible. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.2 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se deber&aacute; informar a la ciudadan&iacute;a que una situaci&oacute;n de robo se est&aacute; efectuando en:&nbsp; nombre de la zona y tipo de robo.</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">NTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">3</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:-1px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><u>&ldquo;Se recomienda a la ciudadan&iacute;a tomar v&iacute;as alternas toda persona que se encuentre en la zona </u></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;</span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><u>mencionada ser&aacute; considerado sospechoso y ser&aacute; reducido inmediatamente.&rdquo;</u></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.3 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Siempre se necesitar&aacute; un m&iacute;nimo de 2 EMS en servicio para poder realizar cualquier tipo&nbsp; de Robo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.4 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No se puede negociar el uso de veh&iacute;culos de los polic&iacute;as o EMS para una posible&nbsp; persecuci&oacute;n.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.5 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Un reh&eacute;n no puede estar m&aacute;s de 30 minutos secuestrado.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.6 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Est&aacute; totalmente proh&iacute;bo la negociaci&oacute;n de intercambio de rehenes por dinero, solo se&nbsp; podr&aacute; negociar por tiempo y/o remoci&oacute;n de objetos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.7 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo, respetar el conteo de la negociaci&oacute;n (No salir antes), por&nbsp; parte del polic&iacute;a negociador.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.8 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de no existir ning&uacute;n acuerdo de negociaci&oacute;n entre ambos bandos o no exista&nbsp; rehenes, se har&aacute; un conteo de 10 segundos para comenzar el tiroteo.&nbsp;&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.9 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Prohibido el usar rehenes c&oacute;mplices o que pertenezcan al mismo bando, por lo cual, si hay un acuerdo de negociaci&oacute;n, estos ser&aacute;n cacheados fuera del lugar del atraco antes del&nbsp; proceder al conteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.10 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de colocar barreras, conos, pinchos, se debe volver a guardar (eliminarlos)&nbsp; luego de levantar el operativo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.11 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de optar por persecuci&oacute;n, los delincuentes solo pueden hacer uso los&nbsp; Veh&iacute;culos de Concesionaria de Gama 1.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1.12 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En una persecuci&oacute;n luego de un robo organizado, los polic&iacute;as tienen permitido obstruir&nbsp; con la patrulla a fin de detener al veh&iacute;culo sospechoso (c&oacute;digo 100). As&iacute; mismo si los&nbsp; delincuentes ponen en peligro la vida de los polic&iacute;as o la de los transe&uacute;ntes; es permitido&nbsp; por la polic&iacute;a el uso de la Clave Rober (Disparar a las llantas).&nbsp;</span></span></span></p>
        
        <p style="text-align:center"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Tipos de Robos.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Tienda.</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:107px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 3 (uno de ellos ser&aacute; el negociador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 2. (No se permite Tirador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 1 solo Reh&eacute;n.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Solo Pistolas Nivel 1.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto al reh&eacute;n. Caso&nbsp; contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Armer&iacute;a.</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:107px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 4 (uno de ellos ser&aacute; el negociador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 3. (No se permite Tirador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 2 Rehenes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Solo Pistolas Nivel 1.</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">NTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">4</span></span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:-1px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto a los rehenes. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;Caso contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Joyer&iacute;a.</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 4 (uno de ellos ser&aacute; el negociador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 3 (Uno de ellos puede hacer rol&nbsp; de tirador fuera de joyer&iacute;a)&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 2 Rehenes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Solo Pistolas Nivel 1.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto a los rehenes.&nbsp; Caso contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px; text-align:center"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Banco Chico (Flecca).</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 5 (uno de ellos ser&aacute; el negociador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 4 (Uno de ellos puede hacer rol&nbsp; de tirador fuera del Banco)&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 3 Rehenes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Armas de Nivel 1, Nivel 2 y chalecos antibalas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto a los rehenes.&nbsp; Caso contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Tienda Tecnol&oacute;gica.</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 6.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:111px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 5 (No se permite Tirador). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No est&aacute; permitido el uso de Fusil o Francotirador&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 3 Rehenes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Armas de Nivel 1, Nivel 2, Nivel 3 y chalecos antibalas. </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto a los rehenes.&nbsp; Caso contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Banco Central</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 7.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 6. (Uno de ellos puede hacer rol&nbsp; de tirador fuera del Banco)&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite 3 Rehenes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:145px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Armas de Nivel 1, Nivel 2, Nivel 3 y chalecos antibalas. </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite solo 1 Francotirador por Ambos bandos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:145px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de llegar a un acuerdo de salida, el negociador se retirar&aacute; junto a los rehenes.&nbsp; Caso contrario y no exista un acuerdo, el negociador ser&aacute; participe del Tiroteo.</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px; text-align:center">&nbsp;</p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">5</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 20.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Derechos de las Personas Arrestadas.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">La P.N.P. en base a la constituci&oacute;n deber&aacute; hacer saber los derechos de las personas que ser&aacute;n&nbsp; privadas de su libertad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.1 Tiene derecho a guardar silencio y a no declarar en su contra.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.2 Tiene derecho a alimentaci&oacute;n y bebida.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.3 Tiene derecho a asistencia m&eacute;dica en caso de que lo requiera.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 21.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Procedimiento Policial.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1. Pedir el documento de identidad a la persona que se realice la intervenci&oacute;n, darle a conocer&nbsp; el motivo de su detenci&oacute;n y revisar si se encuentra en b&uacute;squeda o captura.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">2. Requisarlo en caso de que sea sospechoso&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:74px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">3. En caso de encontrarlo culpable esposarlo y </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>llevarlo a comisar&iacute;a para su procesamiento. </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">4. Leerle los derechos antes de ingresar a comisar&iacute;a&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">5. Al momento de realizar el procesamiento en la comisar&iacute;a colocarle las multas&nbsp; correspondientes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">6. Realizar el registro de antecedentes en comisar&iacute;a&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">7. Enviarlo a prisi&oacute;n el tiempo que corresponda.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 22.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se podr&aacute; detener de manera inmediata a toda persona que:&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se le encuentre cometiendo un delito Infraganti.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se encuentre en b&uacute;squeda y captura&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se d&eacute; a la fuga.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Sean sospechosos debido a denuncias de ciudadanos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se encuentren en una zona de alerta policial.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.1 Es obligaci&oacute;n del oficial leerle los derechos a una persona arrestada antes de ingresar a&nbsp; comisaria, caso contrario y esta es omitida, el arrestado puede reclamar su libertad, pero eso&nbsp; no lo excluye de confiscarle sus objetos ilegales y/o las multas colocadas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.2 Un ciudadano podr&aacute; ser detenido (prisi&oacute;n preventiva) siempre y cuando haya una&nbsp; investigaci&oacute;n de por medio o evidencias en su contra. As&iacute; mismo no tendr&aacute;n derecho al&nbsp; Habeas Corpus (Salir de prisi&oacute;n por omisi&oacute;n de la lectura de los derechos).</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.3 Los Detenidos podr&aacute;n ser identificados por los demandantes o testigos en caso de que </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;existan.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify">&nbsp;</p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 23.&nbsp;&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Una persona arrestada que se encuentra dentro de comisaria tendr&iacute;a que respetar el entorno del lugar y el procesamiento de su detenci&oacute;n realizado por el oficial. As&iacute; mismo el oficial tiene&nbsp; libre potestad de realizar las acciones necesarias a fin de que se cumpla.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 24.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Los detenidos en un acto il&iacute;cito ser&aacute;n juzgados al unison&oacute; y no individualmente tomando en&nbsp; cuenta que el delito cometido lo realizaron como una sociedad.&nbsp;</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">6</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:-1px">&nbsp;</p>
        
        <p style="margin-left:21px">&nbsp;</p>
        
        <p style="text-align:center"><span style="font-size:28.07999992370605pt"><span style="font-family:'Times New Roman'"><span style="color:#000000"><strong>CAPITULO III&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Sistema integral de Multas y Sanciones.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El sistema de multas indica el tiempo que deber&aacute; pagar el ciudadano por la acumulaci&oacute;n de&nbsp; las mismas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 25.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1 mes representa 1 minuto Ingame.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1000 - 10000 $ = 5 &ndash; 10 meses en la comisar&iacute;a.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">10001 - 20000 $ = 11 &ndash; 15 meses en la comisar&iacute;a.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">20001 &ndash; 30000 $ = 16 &ndash; 20 meses en el Penal de m&aacute;xima seguridad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">30001 &ndash; 40000 $ = 21 &ndash; 25 meses en el Penal de m&aacute;xima seguridad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">40001 &ndash; 199999 $ = 26 - 30 meses en el Penal de m&aacute;xima seguridad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>200000 $ en Adelante = Cadena Perpetua (CK) - Traslado a Penal de M&aacute;xima Seguridad.</strong></span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">7</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><img src="https://lh6.googleusercontent.com/blcIfEGMRgE5cggGJsPIQlB8aaiEk4D2uDnyz_6GfXoZ2stXOv0GZj2xXMEbS38Us28Dd2zvWauGrigo5qKAsh3zUMNNC1nUCyNb7JWzscVO_gmRC3Pg30Djp-GoF6TazXpLSznv" style="height:74px; width:68px" /></strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:125px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 26.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:125px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Las personas que tengan un alto perfil delictivo ser&aacute;n consideradas como personas&nbsp; peligrosas en nuestra base de datos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1 - 9 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Antecedentes en la base de datos = </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Persona Pasiva.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>10 - 19 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Antecedentes en la base de datos = </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Persona Neutra.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>20 - 25 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Antecedentes en la base de datos = </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Persona Agresiva.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>26 - 29 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Antecedentes en la base de datos = </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Persona Altamente Peligrosa.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:70px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Las personas que superen los </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>30 antecedentes policiales </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Ser&aacute;n fichados como Terroristas y&nbsp; se proceder&aacute; a realizar una b&uacute;squeda y captura de los mismos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">En caso de ser atrapados se les impondr&aacute; la pena m&aacute;xima de Cadena perpetua (</span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>CK</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">).&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Sistema integral de multas y sanciones (Narc&oacute;ticos)&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 27.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El sistema integral de multas y sanciones (Narc&oacute;ticos) se basar&aacute; en &uacute;nica y exclusivamente los&nbsp; siguientes puntos.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si un civil tiene menos de 10 gramos de droga (Cualquier tipo) se procede a colocarle&nbsp; la multa correspondiente (Portaci&oacute;n de Droga).&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si un civil tiene entre 10-150 gramos de droga (Cualquier tipo) se procede a colocarle&nbsp; la multa (Venta de droga), se confisca la sustancia y se le env&iacute;a a prisi&oacute;n 10 meses. </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si un civil tiene m&aacute;s de 150 gramos de droga </span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Cualquier tipo) se procede a colocarle la&nbsp;multa (Fabricaci&oacute;n de droga), se confisca la sustancia y se le env&iacute;a a prisi&oacute;n por 15&nbsp; meses.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px; text-align:center">&nbsp;</p>
        
        <p style="margin-left:20px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Traslado al Penal de M&aacute;xima Seguridad.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 28.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.1 El traslado a prisi&oacute;n se llevar&aacute; a cabo siempre y cuando supere los 20000 $ de multas y&nbsp; que por lo menos 8 polic&iacute;as se encuentren en servicio para que se realice el traslado (No&nbsp; prioritario a menos que el arrestado lo solicite).&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.3 En caso de que el convoy sea intervenido en pleno traslado se podr&aacute; hacer uso de la fuerza&nbsp; con armamento de Nivel 3.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.4 En caso de que el preso sea liberado motivo de un mot&iacute;n en contra del cuerpo policial el&nbsp; mismo ser&aacute; pr&oacute;fugo de la justicia y se redactara un acta de b&uacute;squeda y captura en su contra.&nbsp;</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.5 La polic&iacute;a debe informar a la ciudadan&iacute;a que se est&aacute; realizando un traslado a prisi&oacute;n y que&nbsp; la polic&iacute;a se encuentra ocupada.</span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px">&nbsp;</p>
        
        <p style="margin-left:21px; margin-right:-1px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.6 En caso de que se haga MetaGaming en este tipo de situaciones se sancionar&aacute; </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;fuertemente.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">1.7. La Polic&iacute;a tiene permitida utilizar todos los niveles de armas en el traslado del&nbsp; delincuente(s), as&iacute; mismo el uso de solo (1) francotirador y chalecos.&nbsp;</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="color:#000000; font-family:Arial; font-size:7.92pt">INTOCABLES RP </span><span style="color:#ffffff; font-family:Calibri,sans-serif"><span style="background-color:#c00000; font-size:14.72px">8</span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:21px; margin-right:-1px">&nbsp;</p>
        
        <p style="text-align:center"><span style="font-size:28.07999992370605pt"><span style="font-family:'Times New Roman'"><span style="color:#000000"><strong>CAPITULO IV&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Niveles de Alerta&nbsp;&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El nivel de Alerta es el encargado de medir el nivel de disponibilidad y defensa del cuerpo&nbsp; policial acatando un estado de emergencia.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Art&iacute;culo 29.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#0070c0">❖ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#0070c0"><strong>Alerta Azul </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Alerta 4).&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se activa cuando existan demasiadas Alertas policiales.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Todo miembro del cuerpo policial tendr&aacute; permitido el intervenir a cualquier ciudadano&nbsp; que se encuentre en actividades sospechosas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se permite el uso de Chaleco Antibalas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#00b050">❖ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#00b050"><strong>Alerta Verde </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Alerta 3).&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se activa cuando existan muchos robos y secuestros a civiles.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Todo miembro del cuerpo policial tendr&aacute; permitido el intervenir a cualquier ciudadano&nbsp; sospechoso con el armamento letal de Nivel 2.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido el realizar el control de v&iacute;as y lugares p&uacute;blicos. </span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido el uso de veh&iacute;culos especiales (Interceptor y&nbsp; Helic&oacute;ptero).&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El uso del Chaleco Antibalas es Obligatorio.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se activa la participaci&oacute;n del Escuadr&oacute;n Verde &ndash; TERNAS. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>(</strong></span></span></span><span style="font-size:10.5600004196167pt"><span style="font-family:Arial"><span style="color:#4d5156"><span style="background-color:#ffffff"><strong>Unidad de Inteligencia </strong></span></span></span></span><span style="font-size:10.5600004196167pt"><span style="font-family:Arial"><span style="color:#4d5156"><strong>&nbsp;</strong></span></span></span><span style="font-size:10.5600004196167pt"><span style="font-family:Arial"><span style="color:#4d5156"><span style="background-color:#ffffff"><strong>T&aacute;ctica Operativa Urbana de la Polic&iacute;a Nacional).</strong></span></span></span></span><span style="font-size:10.5600004196167pt"><span style="font-family:Arial"><span style="color:#4d5156"><strong>&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#ffc000">❖ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffc000"><strong>Alerta Naranja </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Alerta 2).&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se activa cuando un miembro del cuerpo policial es secuestrado o resulta abatido en&nbsp; medio de un tiroteo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido el uso del armamento letal de Nivel 3. </span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido el uso de veh&iacute;culos especiales. (Interceptor,&nbsp; Helic&oacute;ptero, Blindado)&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El uso del Chaleco Antibalas es Obligatorio.</span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">9</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh5.googleusercontent.com/3EM8fT1AxwLpuSqCEXaLXWX7VY36kQOk5LKiGun6SnRby5TMF4uuANCpkB8NndXRqPAM-kW240JfMgbly5c6iB7xYD7Es4DqwP1xU0X8ecYGbGjS6ayoOqkIVjes0nwi3hlxpYHI" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#ff0000">❖ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ff0000"><strong>Alerta Roja </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Alerta 1).&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Se activa cuando la polic&iacute;a no da abasto con las Alertas en la ciudad y existen m&uacute;ltiples&nbsp; secuestros, Robos organizados, Asesinatos, Extorsiones.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido el uso del armamento letal de Nivel 3.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:102px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial podr&aacute; restringir la vialidad en zonas que considere necesarias. </span></span></span></p>
        
        <p style="margin-left:46px; margin-right:102px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial podr&aacute; hacer uso de todos los veh&iacute;culos especiales.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px; margin-right:65px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cuerpo policial tendr&aacute; permitido abrir fuego directo en caso de sentirse amenazado. </span></span></span></p>
        
        <p style="margin-left:46px; margin-right:65px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El uso del Chaleco Antibalas es Obligatorio.&nbsp;</span></span></span></p>
        
        <p style="margin-left:69px"><span style="font-size:11.039999961853027pt"><span style="font-family:Arial"><span style="color:#000000">Atentamente.&nbsp;&nbsp;</span></span></span></p>
        
        <p style="margin-right:90px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Polic&iacute;a Nacional del Per&uacute; &ndash; Intocables RP.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-right:145px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Arial"><span style="color:#000000"><strong><img src="https://lh4.googleusercontent.com/gzPJFdrtYfcJNerBd3DYd6sj8coHffdTCDDvHHteWZYsDv4z2e-EXCV4Hkpj8_OOJ3CmYLLwXG0d81e5r93X3__ckfBvxrl5u6x0kB5Ry_3JN5-PY9ET3LDDdBnk1C6M00biXpUU" style="height:199px; width:184px" /></strong></span></span></span></p>
        
        <p style="margin-left:22px"><span style="font-size:16.079999923706055pt"><span style="font-family:Calibri,sans-serif"><span style="color:#111111"><strong>&iexcl;AVISO!&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:62px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#111111">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#111111">El STAFF se reserva el derecho de editar estas normas cuando lo vea necesario sin&nbsp; previo aviso.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#111111">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#111111">El desconocimiento de estas normas no exime de su cumplimiento.</span></span></span></p>
        
        <p style="margin-right:73px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">10</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh5.googleusercontent.com/jEntsh7l0K_89NOJrRvX93mSWLO-QSgILr8vAlNCdZBCnE0EahF0Lta0vUfcpF0cAdgcJWaHZ9JQvgclKNQWhib6otpE2_UewGkGzLMXy-cICXlSfIqwiZq_PWGNBVj7pK-EO7ad" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="text-align:center"><span style="font-size:28.07999992370605pt"><span style="font-family:'Times New Roman'"><span style="color:#c00000"><strong>Propuestas a Futuro.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:22px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Las siguientes categor&iacute;as son algunas propuestas que pueden desarrollarse m&aacute;s adelante que&nbsp; podr&iacute;an implementarse a fin de dar variedad a el rol de polic&iacute;a actualmente presente.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>1. Nuevos Robos.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo al Yate.</u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong> </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Ubicaci&oacute;n: Zona sur central cerca al restaurante Pier&rsquo;s)&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son m&aacute;ximo 6.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 6 (todos adentro del yate). </span></span></span><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No se permiten rehenes, por tanto, no hay negociaci&oacute;n y es resuelto por tiroteo&nbsp; directo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Armas de Nivel 1 y chalecos antibalas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><u>Robo a Adunas. </u></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">(Ubicaci&oacute;n: Zona sureste de la ciudad).&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad de Polic&iacute;as participantes son 8.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Cantidad m&aacute;ximo de delincuentes participantes son 8.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No se permiten rehenes, por tanto, no hay negociaci&oacute;n y es resuelto por tiroteo&nbsp; directo.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Armas permitidas: Armas de Nivel 1, Nivel 2, Nivel 3 y chalecos antibalas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">∙ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No se permite el uso de Francotirador o fusil.</span></span></span></p>
        
        <p style="margin-right:73px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">11</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><img src="https://lh4.googleusercontent.com/k3gAjyrS_GlSPU1OXosxoOs9sNs7CbDmgxBIczoE3RoPhM43MpV6Hdr9T4j_8LTET356wj1UHSLpVygt6Ww01uv0dXCf5cXo2sn79zUrcLqBpDlMa3YtbVM_HDzgKfeBrV-PCOQq" style="height:74px; width:68px" /></strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>2. Nivel de Alertas Visibles. </strong></span></span></span></p>
        
        <p style="margin-left:45px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El poder Implementar que Nivel de alerta impuesto </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>pueda ser visualizado por toda la&nbsp; ciudad </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">ya sea mediante el logo de la PNP por ejemplo junto a la alerta respectiva, el cual&nbsp; podr&iacute;a estar ubicada en la parte inferior o superior de la pantalla. As&iacute; mismo estas pueden&nbsp; ser activadas o cambiadas con la ayuda de comando exclusivos por cada alerta.&nbsp;&nbsp;</span></span></span></p>
        
        <p style="margin-left:46px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Ejemplos:&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:20px; margin-right:133px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp; </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>&nbsp; </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>3. Sistema de Registro de Informaci&oacute;n IC.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:68px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Poder contar con un sistema de Informaci&oacute;n de Cada ciudadano donde con la ayuda&nbsp; de un comando cada polic&iacute;a pueda observar e ingresar los datos de cada ciudadano y&nbsp; donde se pueda encontrar la lista de antecedentes, licencias, propiedades, casas. Etc.&nbsp;</span></span></span></p>
        
        <p style="margin-left:69px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El sistema podr&iacute;a ser similar al siguiente.&nbsp;</span></span></span></p>
        
        <p style="margin-left:69px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Ejemplo: Link Sistema de Criminalidad IC.&nbsp;</span></span></span></p>
        
        <p style="margin-left:158px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#0563c1"><u>https://forum.cfx.re/t/lrp-criminalrecord/1017021</u></span></span></span></p>
        
        <p style="margin-left:122px">&nbsp;</p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">12</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right">&nbsp;</p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4. Implementaciones que mejorar&iacute;an el Rol Policial.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:58px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.1 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Incorporaci&oacute;n del radar en las patrullas para obtener las patentes de los veh&iacute;culos en persecuci&oacute;n, y as&iacute; por implementar m&aacute;s rol para los que se dan a la fuga. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.2 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Implementar el radar de activaci&oacute;n para la localizaci&oacute;n de los polic&iacute;as en el&nbsp; momento de un tiroteo con el &uacute;nico fin de tener una mejor coordinaci&oacute;n y&nbsp; ubicaci&oacute;n. El cual podr&aacute; activarse o desactivarse solo por los polic&iacute;as ejecutando&nbsp; alg&uacute;n comando IC. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Ejm: </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">/onradar, /offradar.&nbsp;</span></span></span></p>
        
        <p style="margin-left:58px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.3 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Incorporar un &iacute;tem IC que pueda identificar si una persona cuenta con Licencia de&nbsp; Armas o no, el mismo formato podr&iacute;a ser usado tambi&eacute;n para seguros m&eacute;dico,&nbsp; seguros de autos, etc. No valido si en caso ya cont&aacute;ramos con un sistema de&nbsp; Informaci&oacute;n (Punto </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>3</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">), ya que dicha informaci&oacute;n seria encontrada ah&iacute;.&nbsp;</span></span></span></p>
        
        <p style="margin-left:58px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.4 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Establecer que el contador de polic&iacute;as se muestre </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>+5 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">cuando se encuentren m&aacute;s de&nbsp; 6 polic&iacute;as en servicio, a fin de evitar el MG. Tambi&eacute;n aplicable para Essalud,&nbsp; Mec&aacute;nicos, etc.&nbsp;</span></span></span></p>
        
        <p style="margin-left:58px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.5 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Poder contar con un Meg&aacute;fono Ingame, que ser&iacute;a usando dentro de las patrullas,&nbsp; cuyo objetivo es mandar un aviso a una persona sospechosa y esta pueda&nbsp; detenerse. </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Ejm: </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&ldquo;Le habla la Polic&iacute;a, por favor detenga su veh&iacute;culo al lado derecho&nbsp; de la autopista&rdquo;.&nbsp;</span></span></span></p>
        
        <p style="margin-left:58px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>4.6 </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Implementar los </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>servicios comunitarios </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">los cuales ser&iacute;an efectuados a cambio de&nbsp; enviar a prisi&oacute;n y/o multas impuestas.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>5. Procedimiento de Solicitud de Licencia de Armas.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>5.1 Licencia de Armas - Primera Vez.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:68px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em><u>Requisitos:</u></em></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em>&nbsp;</em></strong></span></span></span></p>
        
        <p style="margin-left:94px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Documento de Identidad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:94px; margin-right:69px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Documento de Solicitud de Licencia de Armas (Se adjunta Formato Est&aacute;ndar). </span></span></span></p>
        
        <p style="margin-left:94px; margin-right:69px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No contar con antecedentes cometidos por Delitos Graves.&nbsp;</span></span></span></p>
        
        <p style="margin-left:94px; margin-right:76px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Test Psicol&oacute;gico vigente, el cual es tramitado en las Instalaciones de Essalud. </span></span></span></p>
        
        <p style="margin-left:94px; margin-right:76px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Pago de $10K por derecho de tr&aacute;mite.&nbsp;</span></span></span></p>
        
        <p style="margin-left:68px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em><u>Procedimiento de Aprobaci&oacute;n o Negaci&oacute;n.</u></em></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em>&nbsp;</em></strong></span></span></span></p>
        
        <p style="margin-left:78px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Presentaci&oacute;n de documento de Solicitud de Licencia. El solicitante tendr&aacute; que&nbsp; adjuntar dicho documento previamente rellenado en el canal </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>#solicitudes_pnp. </strong></span></span></span></p>
        
        <p style="margin-left:78px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">La solicitud ser&aacute; evaluada por un cargo superior de la Polic&iacute;a, el cual&nbsp; posteriormente le informara por DM si es Aprobado o Negado. Si su solicitud es&nbsp; Aprobada, se le informara tambi&eacute;n el d&iacute;a y la hora para su entrevista.&nbsp;</span></span></span></p>
        
        <p style="margin-left:78px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El solicitante tendr&aacute; que apersonarse a su entrevista el d&iacute;a y la hora indicada&nbsp; llevando parte de los requisitos anteriormente mencionados. As&iacute; mismo se le&nbsp; cuestionara sobre el uso de Licencia.</span></span></span></p>
        
        <p style="margin-left:120px; margin-right:61px; text-align:justify">&nbsp;</p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">13</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        
        <p style="margin-left:78px; margin-right:-1px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">La licencia de armas ser&aacute; otorgada siempre y cuando sean validados los </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"> requisitos previos. Adem&aacute;s, se informa que dicha licencia tiene vigencia de 1&nbsp; mes, teniendo la posibilidad renovarlo si lo requiere.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Nota. </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Si el solicitante no se apersona en la hora y fecha Indicada, tendr&aacute; derecho a justificar&nbsp; solo una vez el motivo de su ausencia. Posteriormente si se agenda una segunda entrevista y&nbsp; este no se apersona, el solicitante pasara a la &uacute;ltima fila de espera de Solicitudes de Licencias.&nbsp;</span></span></span></p>
        
        <p style="margin-left:45px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>5.2 Renovaci&oacute;n de Vigencia de Licencia de Armas.&nbsp;</strong></span></span></span></p>
        
        <p style="margin-left:68px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em><u>Requisitos:</u></em></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em>&nbsp;</em></strong></span></span></span></p>
        
        <p style="margin-left:94px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Documento de Identidad.&nbsp;</span></span></span></p>
        
        <p style="margin-left:94px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">No contar con antecedentes cometidos por Delitos Graves.&nbsp;</span></span></span></p>
        
        <p style="margin-left:94px; margin-right:61px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Test Psicol&oacute;gico vigente (renovado), el cual es tramitado en las Instalaciones&nbsp; de Essalud.&nbsp;</span></span></span></p>
        
        <p style="margin-left:94px"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Pago de $10K por derecho de tr&aacute;mite.&nbsp;</span></span></span></p>
        
        <p style="margin-left:68px"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em><u>Procedimiento de Renovaci&oacute;n.</u></em></strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong><em>&nbsp;</em></strong></span></span></span></p>
        
        <p style="margin-left:93px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif"><span style="color:#000000">✔ </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El interesado tendr&aacute; que apersonarse a la comisaria y consultar si se encuentra&nbsp; un personal de alto grado que pueda realizarle el tr&aacute;mite de renovaci&oacute;n de su&nbsp; licencia (Prohibido hacer esta consulta v&iacute;a </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>/twt </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">o </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>/anon</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">).&nbsp;</span></span></span></p>
        
        <p style="margin-left:20px; text-align:center"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">___________________________________________________________________________&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px"><span style="font-size:16.079999923706055pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Nota</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">. Para poder desarrollar de forma &oacute;ptima el desarrollo de la solicitud de licencia de&nbsp; armas (Punto 5). Se requiere el apoyo del gobierno, realizado:&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El cambio de nombre al canal actual </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>#postulacion_pnp </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">por el nombre </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>#solicitudes_pnp, </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">el&nbsp; cual ser&aacute; utilizado tanto para solicitud de licencia de armas y solicitudes de Postulaci&oacute;n a la&nbsp; polic&iacute;a, cuyos procedimientos ser&aacute;n posteados en el canal </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>#diario el trome</strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">.&nbsp;</span></span></span></p>
        
        <p style="margin-left:21px; margin-right:61px; text-align:justify"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">El canal </span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>#solicitudes_pnp </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">deber&aacute; mantenerse con la opci&oacute;n de no ver el contenido del mismo&nbsp; o no mostrar historial para los ciudadanos, pero si podr&aacute;n ingresar mensajes, con el &uacute;nico&nbsp; objetivo de mantener la informaci&oacute;n ingresada como confidencial y cuyo acceso o permisos&nbsp; solo podr&aacute; ser visto por Personal de la polic&iacute;a que son altos cargos. Para lo cual ser&iacute;a necesario&nbsp; crear un rol especifico donde este personal tenga permiso a ver el contenido; y a&ntilde;adir&nbsp; reacciones (</span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong>Ejm: </strong></span></span></span><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">Evaluador).&nbsp;</span></span></span></p>
        
        <p style="margin-left:176px"><span style="font-size:11.039999961853027pt"><span style="font-family:Arial"><span style="color:#000000"><strong>Polic&iacute;a Nacional del Per&uacute; &ndash; Intocables RP.</strong></span></span></span></p>
        
        <p style="margin-right:77px; text-align:right"><span style="font-size:7.920000076293945pt"><span style="font-family:Arial"><span style="color:#000000">INTOCABLES RP </span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><span style="background-color:#c00000">14</span></span></span></span><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff">&nbsp;</span></span></span></p>
        
        <p style="margin-right:-1px; text-align:right"><span style="font-size:11.039999961853027pt"><span style="font-family:Calibri,sans-serif"><span style="color:#ffffff"><img src="https://lh4.googleusercontent.com/1zyzP4S-VkvkctITDRDZjH2aTij3WOsuCiE1CTddewPSTjveQ_pmew6CGtiMsb4jAsrZIAvI6-JuiT6a2y2ZoS_qBtuDMon6pDukU2GtKJTrJhIXgxpoQtA8q-tVSE-C6PYC0k5u" style="height:74px; width:68px" /></span></span></span></p>
        </div>
        `,

      }
      this.editorData = this.forum.container;
    }
    if (this.rutaActiva.snapshot.params.container == "reglamento-mecanico") {
      this.forum = null;
      this.editorData = null;
      this.existForun = true;
      this.forum = {
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/466723213724876801/a_073d4d7e0b5e57667fe9882cd7c1fd29.gif",
        "datetime": "30.08.2019",
        "rol": "senior",
        "titlePost": "Reglamento Mecanico",
        "nickname": "! Lil Peluchin",
        "bolComents": false,
        "temas":2,
        "container": `<p style="margin-left:48px; margin-right:79px; text-align:center"><span style="color:#ecf0f1"><span style="font-size:15.960000038146973pt"><span style="font-family:Calibri,sans-serif"><strong>-Reglamento Mec&aacute;nico- </strong></span></span></span></p>

        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Se encuentra totalmente prohibido realizar actos il&iacute;citos estando en servicio. </span><br />
        <span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">En caso de cometer actos il&iacute;citos fuera de servicio y ser reconocido por las&nbsp; autoridades, las mismas tienen el derecho de presentar una denuncia formal ante&nbsp; el jefe de mec&aacute;nicos. La sanci&oacute;n queda a disposici&oacute;n de la administraci&oacute;n del taller&nbsp; dependiendo la gravedad de la denuncia.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Est&aacute; prohibido robar o asesinar a un mec&aacute;nico en servicio, en caso esto ocurra, se&nbsp; deber&aacute; continuar con el rol y reportar v&iacute;a /help o ticket el hecho.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:82px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Al acudir a una alerta siempre se deber&aacute; portar un bid&oacute;n de gasolina y linterna. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">En todo momento se deber&aacute; mostrar respeto ante y con la clientela, caso contrario se le podr&iacute;a colocar una sanci&oacute;n y/o anotaci&oacute;n en incidencias.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Al entrar en servicio, el mec&aacute;nico debe anunciar v&iacute;a /twt que se encuentra en&nbsp; servicio, indicando su nombre completo, rango y el tipo de trabajos que puede&nbsp; realizar.&nbsp;</span></span></span></p>
        
        <p style="margin-left:40px; margin-right:78px; text-align:right"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Siempre que se est&eacute; en servicio se deber&aacute; vestir la ropa de trabajo y estar&nbsp; conectado a la radio (Discord), donde se deber&aacute; registrar en el apartado respectivo, la hora de entrada y salida, en caso contrario esto podr&iacute;a anotarse en incidencias.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido portar cualquier tipo de armas (excepto linterna) o&nbsp; m&aacute;scaras estando en servicio.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido el faltar el respeto a un cliente o compa&ntilde;ero, sin&nbsp; importar el rango, de ser as&iacute; esto conllevar&iacute;a a una sanci&oacute;n.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Todo mec&aacute;nico debe identificarse con nombre y rango cuando alguien lo requiera. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Los seguros vehiculares solo podr&aacute;n ser otorgados por el rango de experimentado&nbsp; en adelante, en caso de que un rango inferior lo remita, este deber&aacute; correr con los&nbsp; gastos de la emisi&oacute;n de un nuevo seguro, redactado por la persona pertinente. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Los seguros vehiculares tienen una validez m&aacute;xima de 1 mes real. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Si un mec&aacute;nico instala una o varias mejoras de forma indebida estas ser&aacute;n pagadas por dicha persona.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta prohibido vender kits a civiles. (Sanci&oacute;n strike/ban)&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Cuando no se encuentre el jefe de mec&aacute;nicos los que quedan a cargo del taller son&nbsp; los gerentes.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Nadie podr&aacute; tomar decisiones que involucren los intereses del taller mientras este&nbsp; no haya sido consultado y aprobado con el o los jefes de mec&aacute;nicos previamente. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Al recibir una alerta es obligatorio responder al cliente que se esta en camino y de&nbsp; presentar retrasos tambi&eacute;n informar de ello.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Ante una falta de respeto por parte del cliente el mec&aacute;nico tiene la potestad de&nbsp; negarse a brindar el servicio ya sea tuning o reparaci&oacute;n. (Esto podr&iacute;a llevar a una&nbsp; inclusi&oacute;n en la lista negra, neg&aacute;ndole cualquier tipo de servicios prestados por el&nbsp; taller).&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">El taller y sus alrededores son considerados zona segura por ende est&aacute; totalmente&nbsp; prohibido iniciar roles agresivos dentro del taller (esto incluye toda la periferia de&nbsp; &eacute;ste hasta sus l&iacute;mites exteriores).</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido que los mec&aacute;nicos oculten en el taller a personas&nbsp; buscadas por la justicia.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Notificar al cliente que si no paga su deuda durante las pr&oacute;ximas 6hs (OOC), se le&nbsp; a&ntilde;adir&aacute; a la lista negra, indic&aacute;ndole que no podr&aacute; utilizar ning&uacute;n servicio de taller. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Est&aacute; prohibido ponerse en servicio para reparar o aplicar tuning solo un veh&iacute;culo y&nbsp; volver a salir despu&eacute;s de arreglarlo o mejorarlo. En caso de ser encontrado&nbsp; realizando esto, ser&aacute; motivo de una sanci&oacute;n y anotaci&oacute;n en incidencias. </span><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido que un mec&aacute;nico aplique mejoras o repare su veh&iacute;culo&nbsp; personal.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido quedarse AFK estando en servicio, en caso de ser&nbsp; necesario estar AFK por un tiempo prolongado debe realizar un aviso previo por&nbsp; radio y salir de servicio.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">En caso de ser el &uacute;nico mec&aacute;nico en servicio y desea marcar su salida, debe avisar&nbsp; por radio de manera escrita que est&aacute; dejando la ciudad sin mec&aacute;nicos, a fin de que&nbsp; si cabe la posibilidad alguno de sus compa&ntilde;eros se ponga en servicio, previniendo&nbsp; que la ciudad se quede sin mec&aacute;nicos.&nbsp;&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Evitar dejar veh&iacute;culos de trabajo tirados o estacionados en los alrededores del&nbsp; taller.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:78px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Si es un nuevo integrante del cuerpo de mec&aacute;nicos, se le dar&aacute; el rango ayudante y&nbsp; tendr&aacute; un plazo de 5 d&iacute;as reales de prueba, plazo en el cual podr&aacute; ser despedido por falencias en su labor, considerando temas de rol, el trato con la clientela o sus&nbsp; compa&ntilde;eros, incumplimiento de las reglas, entre otros.&nbsp;</span></span></span></p>
        
        <p style="margin-left:72px; margin-right:79px; text-align:justify"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif">Pasado los 5 d&iacute;as de prueba, pasa a ser parte de manera oficial del cuerpo de&nbsp; mec&aacute;nicos y no podr&aacute; abandonar el trabajo hasta dentro de los 25 d&iacute;as&nbsp; subsiguientes. En caso de abandonar el taller antes del tiempo establecido, se&nbsp; aplicar&aacute; un ban de X d&iacute;as por incumplimiento de contrato.&nbsp;</span></span></span></p>
        
        <p style="margin-left:48px; margin-right:79px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Ser responsable con el uso de los veh&iacute;culos de la empresa, especialmente con la mesa plana y gr&uacute;a, las cuales se deben manejar con responsabilidad y no confiscar&nbsp; de forma arbitraria.&nbsp;</span></span></span></p>
        
        <p style="margin-left:40px"><span style="color:#dddddd"><span style="font-size:12pt"><span style="font-family:Calibri,sans-serif"><span style="font-size:12pt"><span style="font-family:'Noto Sans Symbols',sans-serif">✓ </span><span style="font-family:Calibri,sans-serif">Esta totalmente prohibido usar veh&iacute;culos personales estando en servicio.</span></span></span></span></span></p>
        `,
      }
      this.editorData = this.forum.container;
    }
    if (this.rutaActiva.snapshot.params.container == "normas-essalud") {
      this.forum = null;
      this.editorData = null;
      this.existForun = true;
      this.forum = {
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/515037229765361664/a_6826f518a974aaae2aec3691672f16ea.gif",
        "datetime": "30.05.2020",
        "rol": "support",
        "titlePost": "Normas Essalud",
        "nickname": "Maurice",
        "bolComents": false,
        "temas":1,
        "container": `<div class="p-2 p-sm-2 p-md-2 p-lg-4" style="background-color:#ffffff">
        <p style="text-align:center"><span style="font-size:20px"><span style="font-family:Arial"><span style="color:#000000"><strong>NORMAS ESSALUD&nbsp;</strong></span></span></span></p>

        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. Cuando entre en servicio se notificar&aacute; diciendo &ldquo;El (Rango + Nombre) se encuentra de servicio.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">Ejemplo: /twt Enfermero Maurice Torres en servicio.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Los de ESSALUD son un cuerpo neutral, por lo tanto, jam&aacute;s podr&aacute; mentir sobre lo que pas&oacute;. Siempre se tiene que decir toda la verdad. No beneficia ni se inclina a favor de ninguna de las partes opuestas o enfrentadas en un conflicto armado.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">3. Prohibido hacer actos delictivos dentro y fuera de servicio (En caso de ser capturado por la polic&iacute;a de la ciudad, se proseguir&aacute; con la expulsi&oacute;n del cuerpo m&eacute;dico, luego de hablar con el jefe m&eacute;dico).&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">4. Llevar el uniforme completo y el veh&iacute;culo respectivo al rango. Hay dos tipos de ambulancias: el veh&iacute;culo todo terreno ser&aacute; utilizado &uacute;nicamente para ir a las alertas que se encuentran lejos de la ciudad; mientras que las grandes, para las cercanas. Esto puede variar dependiendo de la cantidad de EMS en servicio y cantidad de alertas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">5. Llevar solamente la indumentaria de EMS, est&aacute; prohibido llevar armas, drogas, alcohol, entre otros, estando en servicio. El incumplimiento de esta regla ser&aacute; motivo de una sanci&oacute;n.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">6. Se deben respetar las leyes de tr&aacute;nsito, exceptuando cuando se tenga una alerta, pero siempre y cuando sin poner en riesgo la vida de los dem&aacute;s.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">7. Respetar a los civiles, compa&ntilde;eros y superiores.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">8. Est&aacute; totalmente prohibido quedarse AFK. En caso de necesitar estar AFK por alg&uacute;n motivo, deber&aacute; quitarse el uniforme y salir de servicio, y comunicarlo a sus compa&ntilde;eros en la radio.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">9. Al momento de estar en servicio, ser&aacute; obligatorio estar conectado a la radio, para una mejor organizaci&oacute;n de las alertas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">10. Al momento de salirse de servicio, se deber&aacute; avisar por radio, tratando de no dejar la ciudad sin ESSALUD en momentos de alto movimiento.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">11. Las personas que tienen como causa &ldquo;Probablemente muri&oacute; por una explosi&oacute;n&rdquo; no deben ser atendidas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">12. Est&aacute; totalmente prohibido difundir cualquier tipo de informaci&oacute;n y/o documentaci&oacute;n de civiles que se encuentre otorgada en el sistema del cuerpo m&eacute;dico (radio/discord). El incumplimiento de esta regla ser&aacute; motivo de sanci&oacute;n.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">13. El jefe, supervisores y m&eacute;dicos (con el permiso de los rangos antes mencionados) pueden activar la &ldquo;Alerta Roja&rdquo;, esta consiste que en el momento que tengamos una alta cantidad de alertas solo colocaremos dos vendas a los civiles.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>FUERA DE SERVICIO&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. Un personal de salud fuera de servicio NO podr&aacute; utilizar ambulancias.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. No se podr&aacute; poner en servicio para reanimar a X persona y volver a salir despu&eacute;s de reanimarlo. En caso de ser encontrado realizando esto, ser&aacute; motivo de Advertencia. 3. Cuando te salgas del servicio tendr&aacute;s que avisar a los compa&ntilde;eros mediante la radio y notificar por el chat mediante /twt junto a su rango y nombre.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">Ejemplo: /twt Enfermero Ignacio Cicero fuera de servicio. &iexcl;Valoren sus vidas! </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>LLAMADOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. Las llamadas policiales tienen prioridad ante cualquier otra llamada.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Cualquier llamada faltando respeto al cuerpo m&eacute;dico no ser&aacute; atendida. 3. Las llamadas que se encuentren en el agua NO ser&aacute;n atendidas.</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">4. Las llamadas fuera de la ciudad pueden ser atendidas por cualquier ESSALUD sin importar el rango, siempre yendo sin acompa&ntilde;antes ya que al ser una larga distancia se estar&iacute;a ocupando m&aacute;s personal del necesario por un largo tiempo.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>RADIO&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. La radio es un elemento de trabajo que debe utilizarse para no atender dos param&eacute;dicos la misma llamada. (Radio EMS)&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Mantener el orden y respeto en todo momento. Se recomienda evitar hablar temas externos en momentos de alta actividad de alertas, debido a que pueden incomodar y/o interrumpir a los dem&aacute;s durante la asistencia m&eacute;dica.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">3. Cuando un compa&ntilde;ero est&aacute; hablando de algo importante no hay que interrumpirlo. 4. En caso de tener alguna duda se podr&aacute; preguntar a un rango superior o al jefe sin problemas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">5. Se recomienda no utilizar la radio para conversar entre 2 personas que se encuentran al lado, ya que tranquilamente pueden hablar in-game.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>ROBOS O TIROTEOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. Al momento de acudir a un robo o tiroteo, recordar de posicionarse a una distancia prudencial, es decir 100 metros o m&aacute;s.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Para proceder se deber&aacute; preguntar por /twt EMS Esperando indicaciones en tiroteo (Calle y/o ubicaci&oacute;n)&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">Ejem: /twt EMS esperando indicaciones en tiroteo de West Mirror cerca de la tienda. 3. Al momento de recibir una respuesta para poder proceder, se deber&aacute; dar prioridad a reanimar al grupo vencedor y seguir instrucciones dadas por este.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">4. Siempre se le cobra al grupo perdedor. (Procedimientos policiales nunca se cobra). </span></span></span><span style="font-size:9.007556915283203pt"><span style="font-family:Arial"><span style="color:#000000">Nota: Recuerden que el mal realizar de estos procedimientos es motivo de Advertencia.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>PROCEDIMIENTO PARA CIVILES HERIDOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. En primer lugar, y muy importante, cerciorarse de que nos encontramos en una posici&oacute;n segura.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Existen m&uacute;ltiples causas de muerte, y para cada una, se debe proceder de diferente forma. 3. Debemos ver el entorno, si es que es un posible tiroteo que est&aacute; empezando y/o hay personas alrededor sospechosas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">4. Si es m&aacute;s de un civil, procurar primordialmente reanimar a todos los civiles antes de colocar vendajes.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">5. Busca las heridas, los orificios de entrada y salida si hubiera heridas de bala, analiza el cuerpo de la v&iacute;ctima en busca de hemorragias.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">6. Contener hemorragias y cubrir heridas dependiendo la parte del cuerpo en la que se encuentren.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">7. Aplicar RCP al reanimar al civil o retirar las balas y suturar las heridas, en caso de que sea necesario y dependiendo de la causa.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">8. Aplicar vendas en caso de ser necesario.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">9. Al momento de realizar este proceso, no olvidar el uso del /do para dar m&aacute;s juego al rol. </span></span></span><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>TIPOS DE TRATAMIENTOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Vendajes: Casi para todo.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Pomada: Para desinflamar heridas/hemorragias.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Alcohol: Para desinfectar heridas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Curitas: Para heridas leves.</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>TIPOS DE MEDICAMENTOS&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Aspirina: Casi para todo.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Simvastatina: Para controlar el colesterol.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Omeprazol: Para la acidez de est&oacute;mago.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">➔ Ramipril: Para la hipertensi&oacute;n.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>TIERRA DE NADIE&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><em>&quot;LOS BARRACONES&quot;&nbsp;</em></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000"><strong>REGLAS:&nbsp;</strong></span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">1. Se puede disparar sin rol previo.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">2. Valorar la vida queda inhabilitado.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">3. No se permite el uso de veh&iacute;culos dentro de la zona.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">4. Al morir dentro de la zona es PK autom&aacute;tico y tienes que forzar la reaparici&oacute;n. 5. Puedes robar las casas dentro de la zona.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">6. Puedes robar a las personas dentro de la zona.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">7. Las facciones legales tienen prohibido ir a este punto.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">8. Est&aacute; prohibido mandar alerta al ESSALUD. (Sancionable)&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">9. M&aacute;ximo de participantes por grupo: 8 personas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">10. Los chalecos est&aacute;n permitidos.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">11. Solo est&aacute; permitido matar con pistolas.&nbsp;</span></span></span></p>
        
        <p><span style="font-size:11.004231452941895pt"><span style="font-family:Arial"><span style="color:#000000">12. No es necesario esperar los 20 min para regresar al punto.</span></span></span></p>
        
        <p>&nbsp;</p>
        </div>`,
      }
      this.editorData = this.forum.container;
    }
  }
  }

  public onChange( event: CKEditor4.EventInfo ) {
    this.editorData = event.editor.getData() ;
    console.log( event.editor.getData() );
}

}

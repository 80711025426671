import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';


import { ArticleFeComponent } from './component/estruct/article-fe/article-fe.component';
import { ForoContainerComponent } from './component/foro-container/foro-container.component';
import { ForoComponent } from './component/foro/foro.component';


const routes: Routes = [
  {
    path: 'forums',
    component: ArticleFeComponent
  },
  {
    path: '',
    redirectTo: 'forums',
    pathMatch: 'full'
  },
  {
    path: 'forums/:blog',
    component: ForoComponent
  },
  { path: 'forums/:blog/:container', component: ForoContainerComponent },
  { path: 'forums/:blog/:container/**', redirectTo: 'forums', pathMatch: 'full' },
  { path: '**', redirectTo: 'forums', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true ,
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component} from '@angular/core';



@Component({
  selector: 'nav-bar-fe',
  templateUrl: './nav-bar-fe.component.html',
  styleUrls: ['./nav-bar-fe.component.scss']
})
export class NavBarFeComponent {

  constructor(

  ) {
    
  }
 


}

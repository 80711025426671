import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ArticlesI } from 'src/app/model/article.interface';
import { ForoAutorI } from 'src/app/model/foro-autor.interface';
import { ForoI } from 'src/app/model/foro.interface';

@Component({
  selector: 'app-foro',
  templateUrl: './foro.component.html',
  styleUrls: ['./foro.component.scss']
})
export class ForoComponent implements OnInit {
  articles: Array<ArticlesI>;
  title: String;
  forum: ForoI;
  foroAutor: Array<ForoAutorI>;
  existForun: boolean;
  coche: { marca: string, modelo: string };
  constructor(
    private rutaActiva: ActivatedRoute
  ) {
    this.existForun = false;
   
  }

  ngOnInit() {
    this.forumss();
  
    this.coche = {
      marca: this.rutaActiva.snapshot.params.blog,
      modelo: "aasfwasf"
    };
 //   console.log(this.rutaActiva.snapshot.params.blog);


  }
  forumss() {
    if (this.rutaActiva.snapshot.params.blog == "informacion") {
      this.forum = null;
      this.existForun = true;
      this.foroAutor =[
        {
          "urlUserAvatar": "https://cdn.discordapp.com/avatars/466723213724876801/a_073d4d7e0b5e57667fe9882cd7c1fd29.gif",
          "datetime": "03-12-2020",
          "rol": "senior",
          "titlePost": "Reglamento General",
          "urlTitle": "reglamento-general",
          "nickname": "! Lil Peluchin",
          "bolComents": false,
        },
        {
          "urlUserAvatar": "https://cdn.discordapp.com/avatars/652196208726638592/07299d18c59eca76344dd970d768bdbd.png",
          "datetime": "03-12-2020",
          "rol": "admin",
          "titlePost": "Normas Generales de la Policía",
          "urlTitle": "normas-generales-de-la-policía",
          "nickname": "IgnL",
          "bolComents": false,
        },
        {
          "urlUserAvatar": "https://cdn.discordapp.com/avatars/515037229765361664/a_6826f518a974aaae2aec3691672f16ea.gif",
          "datetime": "03-13-2020",
          "rol": "support",
          "titlePost": "Normas Essalud",
          "urlTitle": "normas-essalud",
          "nickname": "Maurice",
          "bolComents": false,
        },
        {
          "urlUserAvatar": "https://cdn.discordapp.com/avatars/466723213724876801/a_073d4d7e0b5e57667fe9882cd7c1fd29.gif",
          "datetime": "03-12-2020",
          "rol": "senior",
          "titlePost": "Reglamento Mecanico",
          "urlTitle": "reglamento-mecanico",
          "nickname": "! Lil Peluchin",
          "bolComents": false,
        }

      ];
      this.forum = {
        "logo": "INF",
        "styleLogo": "inf",
        "title": "Información",
        "subtitle": "Reglas Generales, Policiales, medico",
        "autorInfo": this.foroAutor
      }


   
    }
  }
}

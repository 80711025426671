import { Component, OnInit } from '@angular/core';
import { ArticlesI } from 'src/app/model/article.interface';

@Component({
  selector: 'article-fe',
  templateUrl: './article-fe.component.html',
  styleUrls: ['./article-fe.component.scss']
})
export class ArticleFeComponent {
 articles:Array<ArticlesI> ;

  constructor() {
    this.articles = [
      { 
        "logo": "INF",
        "styleLogo": "inf",
        "urlTitle":"informacion",
        "title": "Información",
        "subtitle": "Reglas Generales, Policiales, medico",
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/466723213724876801/a_073d4d7e0b5e57667fe9882cd7c1fd29.gif",
        "datetime": "03-12-2020",
        "rol": "senior", 
        "titlePost": "Reglamento General", 
        "nickname": "! Lil Peluchin", 
        "cuantityPost": 3, 
      },
      { 
        "logo": "NOT",
        "styleLogo": "not",
        "urlTitle":"noticias",
        "title": "Noticias",
        "subtitle": "Informacion sobre los eventos, actualizaciones, postulaciones",
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/77619273077366784/e6b464b9aea3f70949d3d4897b07ebe2.png",
        "datetime": "16/12/2020",
        "rol": "ceo", 
        "titlePost": "Ganadores del Evento de Discord", 
        "nickname": "Mr.Choco", 
        "cuantityPost": 19, 
      },
      { 
        "logo": "BUG",
        "styleLogo": "bug",
        "urlTitle":"bugging-report",
        "title": "Bugging Report",
        "subtitle": "bugs",
        "urlUserAvatar": "https://cdn.discordapp.com/avatars/486290136267489300/ab1e6fdf6f6299f470a6c607019386aa.png",
        "datetime": "17/12/2020",
        "rol": "user", 
        "titlePost": "Reglamento docs", 
        "nickname": "TorttugaCrush", 
        "cuantityPost": 1, 
      }
    ];
  

}

}
